/* eslint-disable max-len */
export const description = [
  `Nous sommes fiers de vous présenter Abrupt notre premier model de guitare, 
  Nous avons voulu créer ici un design épuré oscillant entre moderne et vintage au look unique mais à la jouabilité reprenant les caractéristiques les plus convoitées des instruments vintage. 
  Une conception exigeante alliant design et ergonomie, utilisant des essences de bois responsables et matériaux de qualité.`,
  'Un instrument versatile s’adaptant à toutes les situations grâce à plus de deux ans de recherche et développement.',
  'Une fabrication à la main 100% française.',
  'Comme tout nos produits Old Tree Instruments nous avions la volonté de vous proposer la possibilité de configurer votre instrument avec un large choix de bois, hardware, options et finitions afin que votre guitares soient uniques et taillées sur mesure selon vos goûts.',
]

export const initialPrice = 1690

export const boisCorpOptions = {
  key: 'Bois corp',
  options: [
    {
      id: 'Aulne-bois-corp',
      title: 'Aulne',
      price: 0,
    },
    {
      id: 'Noyer-bois-corp',
      title: 'Noyer (+50€)',
      price: 50,
    },
  ],
}

export const boisMancheOptions = {
  key: 'Bois manche',
  options: [
    {
      id: 'érable-bois-manche',
      title: 'Érable',
      price: 0,
    },
    {
      id: 'noyer-bois-manche',
      title: 'Noyer',
      price: 0,
    },
  ],
}

export const toucheOptions = {
  key: 'Touche',
  options: [
    {
      id: 'Érable-touche',
      title: 'Érable',
      price: 0,
    },
    {
      id: 'Noyer-touche',
      title: 'Noyer',
      price: 0,
    },
  ],
}

export const profileMancheOptions = {
  key: 'Profils de manche',
  options: [
    {
      id: 'Profil-C',
      title: 'Profil C',
      price: 0,
    },
    {
      id: 'Profil-slim-D',
      title: 'Profil slim D',
      price: 0,
    },
  ],
}

export const diapasonOptions = {
  key: 'Diapason',
  options: [
    {
      id: '25,5',
      title: '25,5’’',
      price: 0,
    },
  ],
}

export const frettesOptions = {
  key: 'Frettes',
  options: [
    {
      id: 'Hosco-medium-22-frettes',
      title: 'Hosco medium 22 frettes',
      price: 0,
    },
  ],
}

export const repereToucheOptions = {
  key: 'Repères de touche',
  options: [
    {
      id: 'Noir-repere-touche',
      title: 'Noir',
      price: 0,
    },
    {
      id: 'Blanc-repere-touche',
      title: 'Blanc',
      price: 0,
    },
    {
      id: 'Cuivre-repere-touche',
      title: 'Cuivre (+20€)',
      price: 20,
    },
    {
      id: 'Laiton-Dore-repere-touche',
      title: 'Laiton Doré (+20€)',
      price: 20,
    },
  ],
}

export const bindingCorpOptions = {
  key: 'Binding corp',
  options: [
    {
      id: 'Noir-binding-corp',
      title: 'Noir',
      price: 0,
    },
    {
      id: 'Blanc-binding-corp',
      title: 'Blanc',
      price: 0,
    },
    {
      id: 'Creme-binding-corp',
      title: 'Crème',
      price: 0,
    },
  ],
}

export const bindingMancheOptions = {
  key: 'Binding manche',
  options: [
    {
      id: 'Noir-binding-manche',
      title: 'Noir',
      price: 0,
    },
    {
      id: 'Blanc-binding-manche',
      title: 'Blanc',
      price: 0,
    },
    {
      id: 'Creme-binding-manche',
      title: 'Crème',
      price: 0,
    },
  ],
}

export const pickguardOptions = {
  key: 'Pickguard',
  options: [
    {
      id: 'Noir-3-plis-pickguard',
      title: 'Noir 3 plis',
      price: 0,
    },
    {
      id: 'Noir-1-pli-pickguard',
      title: 'Noir 1 pli',
      price: 0,
    },
    {
      id: 'Blanc-3-plis-pickguard',
      title: 'Blanc 3 plis',
      price: 0,
    },
    {
      id: 'Blanc-1-pli-pickguard',
      title: 'Blanc 1 pli',
      price: 0,
    },
    {
      id: 'Tortoise-3-plis-pickguard',
      title: 'Tortoise 3 plis',
      price: 0,
    },
  ],
}

export const silletTeteOptions = {
  key: 'Sillet de tête',
  options: [
    {
      id: 'Graph-Tech-tusk-sillet',
      title: 'Graph Tech tusk',
      price: 0,
    },
  ],
}

export const chevaletOptions = {
  key: 'Chevalet',
  options: [
    {
      id: 'Schaller-3D-6-chrome',
      title: 'Schaller 3D-6 chrome',
      price: 0,
    },
    {
      id: 'Schaller-3D-6-noir',
      title: 'Schaller 3D-6 noir',
      price: 0,
    },
    {
      id: 'Schaller-3D-6-dore',
      title: 'Schaller 3D-6 doré (+40€)',
      price: 40,
    },
  ],
}

export const mechaniquesOptions = {
  key: 'Mechaniques',
  options: [
    {
      id: 'Gotoh-SG360-01-chrome',
      title: 'Gotoh SG360-01 chrome',
      price: 0,
    },
    {
      id: 'Gotoh-SG360-01-noire',
      title: 'Gotoh SG360-01 noire',
      price: 0,
    },
    {
      id: 'Gotoh-SG360-01-doré',
      title: 'Gotoh SG360-01 doré (+20€)',
      price: 20,
    },
    {
      id: 'Hipshot-Griplock-chrome',
      title: 'Hipshot Griplock chrome (+100€)',
      price: 100,
    },
    {
      id: 'Hipshot-Griplock-noir',
      title: 'Hipshot Griplock noir (+100€)',
      price: 100,
    },
    {
      id: 'Hipshot-Griplock-doré',
      title: 'Hipshot Griplock doré (+100€)',
      price: 100,
    },
  ],
}

export const microChevaletOptions = {
  key: 'Micro chevalet',
  options: [
    {
      id: 'Insomnia-OTI—mini-humbucker',
      title: 'Insomnia OTI custom mini humbucker',
      price: 0,
    },
  ],
}

export const microMancheOptions = {
  key: 'Micro manche',
  options: [
    {
      id: 'Insomnia OTI custom mini humbucker',
      title: 'Insomnia OTI custom mini humbucker',
      price: 0,
    },
    {
      id: 'Insomnia OTI custom P90 format mini HB',
      title: 'Insomnia OTI custom P90 format mini HB',
      price: 0,
    },
    {
      id: 'None-micro-manche',
      title: 'None',
      price: 0,
    },
  ],
}

export const configElectroniqueOptions = {
  key: 'Configuration électronique',
  options: [
    {
      id: '1 volume + switch 3 positions',
      title: '1 volume + switch 3 positions',
      price: 0,
    },
    {
      id: '1 volume + 1 tone + switch 3 positions',
      title: '1 volume + 1 tone + switch 3 positions (+30€)',
      price: 30,
    },
    {
      id: '2 volume + switch 3 positions (+30€)',
      title: '2 volume + switch 3 positions (+30€)',
      price: 30,
    },
  ],
}

export const finitionMancheOptions = {
  key: 'Finition manche',
  options: [
    {
      id: 'Bois naturel + Vernis mat',
      title: 'Bois naturel + Vernis mat',
      price: 0,
    },
  ],
}
export const finitionCorpOptions = {
  key: 'Finitions corp',
  options: [
    {
      id: 'Bois naturel + vernis mat finition corp',
      title: 'Bois naturel + vernis mat',
      price: 0,
    },
    {
      id: 'Table peinte, fond bois naturel + vernis mat finition corp',
      title: 'Table peinte, fond bois naturel + vernis mat (+50€)',
      price: 50,
    },
    {
      id: 'Corp peint + vernis mat finition corp',
      title: 'Corp peint + vernis mat (+100€)',
      price: 100,
    },
  ],
}

export const allOptions = [
  bindingCorpOptions,
  bindingMancheOptions,
  boisCorpOptions,
  boisMancheOptions,
  chevaletOptions,
  configElectroniqueOptions,
  finitionCorpOptions,
  finitionMancheOptions,
  frettesOptions,
  mechaniquesOptions,
  microChevaletOptions,
  microMancheOptions,
  pickguardOptions,
  profileMancheOptions,
  repereToucheOptions,
  silletTeteOptions,
  toucheOptions,
  diapasonOptions,
]

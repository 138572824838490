import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { string } from 'prop-types'
import styled from 'styled-components'

import Img from 'gatsby-image'

const query = graphql`
  {
    bois: file(relativePath: {
      eq: "guitar/guitar-bois.jpeg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    binding1: file(relativePath: {
      eq: "guitar/binding-1.jpeg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hardware1: file(relativePath: {
      eq: "guitar/hardware-1.jpeg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    electronique: file(relativePath: {
      eq: "guitar/electronique.jpeg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    finition: file(relativePath: {
      eq: "guitar/finition.jpeg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Wrapper = styled.div`
  // flex: 1;
  padding: 10px 0px;
`

export const ImageBlock = ({ name }) => {
  const data = useStaticQuery(query)

  const img = data[name]

  return (
    <Wrapper>
      <Img fixed={img.childImageSharp.fixed} />
    </Wrapper>
  )
}

ImageBlock.propTypes = {
  name: string.isRequired,
}

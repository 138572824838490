import { genDevis } from '../Form'

export const getTotal = (allOptions, currentOptionsValues, initialAmount = 0) =>
  Object.entries(currentOptionsValues)
    .reduce((acc, [key, value]) => {
      const selector = allOptions.find(opt => opt.key === key)
      if (!selector) return acc

      const selectedOpt = selector.options.find(opt => opt.id === value)
      if (!selectedOpt) return acc
      return acc + selectedOpt.price
    }, initialAmount)

export const getGuitarDevis = (allOptions, selectedOptionsValues, total) => {
  const formattedData = Object
    .entries(selectedOptionsValues)
    .map(([key, value]) => {
      const selector = allOptions.find(opt => opt.key === key)
      if (!selector) return null

      const selectedOpt = selector.options.find(opt => opt.id === value)
      if (!selectedOpt) return null

      return { label: key, value: selectedOpt.title }
    })
    .filter(Boolean)

  return genDevis('Guitare', formattedData, total)
}

import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import Guitar from '../../components/Guitar'

export const query = graphql`
  {
    file(relativePath: { eq: "guitar/guitar-home.jpeg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const GuitarPage = ({ data }) => (
  <Layout>
    <SEO
      title="Guitare"
      keywords={[
        'guitare', 'guitar', 'music', 'music gear',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <Guitar />
  </Layout>
)

GuitarPage.propTypes = {
  data: shape({}).isRequired,
}

export default GuitarPage

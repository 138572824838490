import styled from 'styled-components'

import {
  DevisTitle as CommonDevisTitle,
  TitleSection as CommonTitleSection,
} from '../Cabs/styles'

import CommonSelect from '../Select'

export const DevisTitle = styled(CommonDevisTitle)`
  margin-bottom: 20px;
  flex-wrap: wrap;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: fit-content;
`

export const Section = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap-reverse;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
`

export const TitleSection = styled(CommonTitleSection)`
  text-align: left;
  font-size: ${({ theme: { size } }) => size.fontSize.l};
`

export const LeftSection = styled.div`
  min-width: 350px;
`

export const RightSection = styled.div``

export const SelectorWrapper = styled.div`
  padding-bottom: 20px
`

export const Select = styled(CommonSelect)`
  margin-top: 0px;
`

export const SelectLabel = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  color: ${({ theme: { color } }) => color.secondary.main};
  padding-bottom: 10px;

`
